import api from './api';

class ArchivoService {

  getItems(id, cadenaBusqueda) {
    cadenaBusqueda = cadenaBusqueda ? cadenaBusqueda : ''
    return api.get(`/api/layouts/${id}/archivos${cadenaBusqueda}`);
  }

  getArchivosRepositorioFotografico(id) {
    return api.get(`/api/archivos_repositorio_fotografico/`);
  }

  getArchivo(layoutId, archivo) {
    return api.get(`/api/layouts/${layoutId}/archivos/${archivo.id}`, { responseType: 'blob' });
  }

  cargarBD(layoutId, archivo) {
    return api.get(`/api/layouts/${layoutId}/cargar_bd/archivos/${archivo.id}`);
  }

  generarVariables(layoutId, archivo) {
    return api.post(`/api/layouts/${layoutId}/variables/archivos/${archivo.id}`);
  }
  getArchivoVariables(layoutId, archivo) {
    return api.get(`/api/layouts/${layoutId}/variables/archivos/${archivo.id}`, { responseType: 'blob' });
  }
  uploadFile(id, file) {
    return api.post(`/api/layouts/${id}/archivos`, file, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  deleteItem(layoutId, archivo) {
    return api.delete(`/api/layouts/${layoutId}/archivos/${archivo.id}`);
  }

  getArchivosPendientes() {
    return api.get(`/api/archivos_pendientes`);
  }

  getVariablesPendientes() {
    return api.get(`/api/variables_pendientes`);
  }
}

export default new ArchivoService();