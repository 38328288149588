<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title
        >Configuración para Ejecutar el Proceso de Conciliación
        {{ proceso.nombre }}</v-card-title
      >
      <v-divider></v-divider>
      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <div
                v-if="loadingArchivos"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingArchivos"
                v-model="archivo"
                :items="archivos"
                :item-text="'id_nombre'"
                :item-value="'id'"
                attach
                :label="
                  'Archivo del Layout Principal: ' + proceso.fuente.nombre
                "
                :rules="required"
                return-object
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <div
                v-if="loadingArchivosComparacion"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingArchivosComparacion"
                v-model="archivoComparacion"
                :items="archivosComparacion"
                :item-text="'id_nombre'"
                :item-value="'id'"
                attach
                :label="
                  'Archivo del Layout de Comparación: ' + proceso.fuente_comparacion.nombre
                "
                :rules="required"
                return-object
              ></v-select>
            </v-col>
          </v-row>

        </v-container>
      </v-form>
      <v-divider></v-divider>
      <!-- <pre>{{proceso}}</pre> -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <CButton color="secondary" @click="preCancelar">Cancelar</CButton>&nbsp;
        <div v-if="loadingEjecutar" class="spinner-border" role="status"></div>
        <CButton
          v-if="!loadingEjecutar"
          color="primary"
          class="px-4"
          @click="ejecutar()"
          :disabled="!valid"
          >Ejecutar >></CButton
        >
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialog" max-width="420">
      <v-card>
        <v-card-title class="text-h5">
          ¿Desea cancelar la edición?
        </v-card-title>

        <v-card-text>
          Si cancela la edición los datos diligenciados no quedarán guardados.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="goBack">
            Cancelar y volver
          </v-btn>

          <v-btn color="green darken-1" text @click="dialog = false">
            Continuar la edición
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EjecucionProcesoConciliadorService from "@/services/ejecucionProcesoConciliador.service";
import ArchivoService from "@/services/archivo.service";
import PasoProcesoConciliadorService from "@/services/pasoProcesoConciliador.service";
import validationField from "@/util/validationField";
import LayoutService from "@/services/layout.service";
import ListaService from "@/services/lista.service";
import { mapState } from "vuex";

export default {

  data() {
    return {
      proceso: {
        nombre: undefined,
        fuente_id: undefined,
        fuente_comparacion_id: undefined,
        descripcion: undefined,
        activo: true,
      },
      valid: false,
      rules: [
        (value) => !!value || "Requerido",
        (value) => (value && value.length >= 3) || "Mínimo 3 caracteres",
      ],
      required: [(value) => !!value || "Requerido"],
      // headers: [
      //   { text: "Fuentes Encontradas en el Proceso", value: "fuente_nombre", sortable: false },
      //   { text: "Seleccionar Archivo de la Fuente", value: "id_cargue", sortable: false },
      // ],
      archivo: undefined,
      archivoComparacion: undefined,
      archivos: [],
      loading: false,
      loadingArchivos: false,
      loadingArchivosComparacion: false,
      loadingEjecutar: false,
      message: "",
      dialog: false,
    };
  },
  computed: {
    ...mapState(["permissions"]),
  },
  watch: {},
  mounted() {
    if (!this.permissions.includes("Definición de proceso de conciliador"))
      this.$router.push("/dashboard");

    if (localStorage.getItem("datos_proceso_conciliador")) {
      this.proceso = JSON.parse(
        localStorage.getItem("datos_proceso_conciliador")
      );
      this.fetchArchivos("");
      this.fetchArchivosComparacion("");
    }

  },
  methods: {
    ejecutar() {
      this.loadingEjecutar = true;
      EjecucionProcesoConciliadorService.ejecutar(this.proceso, { archivo: this.archivo, archivoComparacion: this.archivoComparacion }).then(
        (response) => {
          console.log(response.data);
          this.loadingEjecutar = false;
          this.$router.push({ path: `/conciliador/procesos-conciliador/ejecuciones` });
        },
        (error) => {
          this.content =
            ( error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingEjecutar = false;
          this.$router.push({ path: `/conciliador/procesos-conciliador/ejecuciones` });
        }
      );
    },
    fetchArchivos(cadenaBusqueda) {
      this.loadingArchivos = true;
      console.log(this.proceso);
      ArchivoService.getItems(this.proceso.fuente_id, cadenaBusqueda).then(
        (response) => {
          this.archivos = response.data;
          this.archivos = this.archivos.map(function f(x) {
            return { ...x, id_nombre: x.id + " - " + x.nombre };
          });
          console.log("Fuente principal")
          console.log(this.archivos);
          this.loadingArchivos = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingArchivos = false;
        }
      );
    },
    fetchArchivosComparacion(cadenaBusqueda) {
      this.loadingArchivosComparacion = true;
      console.log(this.proceso);
      ArchivoService.getItems(this.proceso.fuente_comparacion_id, cadenaBusqueda).then(
        (response) => {
          this.archivosComparacion = response.data;
          this.archivosComparacion = this.archivosComparacion.map(function f(x) {
            return { ...x, id_nombre: x.id + " - " + x.nombre };
          });
          console.log("Fuente comparación")
          console.log(this.archivosComparacion);
          this.loadingArchivosComparacion = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingArchivosComparacion = false;
        }
      );
    },
    
    preCancelar() {
      this.dialog = true;
    },
    goBack() {
      this.$router.push({ path: "/conciliador/procesos-conciliador" });
    },
  },
};
</script>

<style>
</style>
