import api from './api';

class EjecucionProcesoConciliadorService {

  ejecutar(procesoConciliador, request) {
    return api.post(`/api/ejecutar_proceso_conciliador/${procesoConciliador.id}`, request);
  }

  continuarEjecucion(ejecucion_id, request) {
    return api.post(`/api/continuar_ejecucion_proceso_conciliador/${ejecucion_id}`, request);
  }

  deleteItem(procesoId, id) {
    return api.delete(`/api/procesos_conciliador/${procesoId}/ejecuciones_proceso_conciliador/${id}`)
  }

  getArchivo(params) {
    return api.get(`/api/descargar_proceso_conciliador/${params.id}`, { responseType: 'blob' });
  }

  getArchivoFuente(params) {
    return api.get(`/api/descargar_proceso_conciliador_fuente/${params.id}`, { responseType: 'blob' });
  }

  getArchivoFuenteComparacion(params) {
    return api.get(`/api/descargar_proceso_conciliador_fuente_comparacion/${params.id}`, { responseType: 'blob' });
  }

  getItems(id, cadenaBusqueda) {
    cadenaBusqueda = cadenaBusqueda ? cadenaBusqueda : ''
    return api.get(`/api/procesos_conciliador/${id}/ejecuciones_proceso_conciliador${cadenaBusqueda}`);
  }

  getEjecucionesPendientes() {
    return api.get(`/api/ejecuciones_pendientes_proceso_conciliador`);
  }
}

export default new EjecucionProcesoConciliadorService();
